import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useEditController, useListContext } from 'ra-core';
import { DateField, Labeled, linkToRecord, TextField } from 'react-admin';
import { Link } from 'react-router-dom';
import LinkField from '../components/LinkField';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const CustomerCard = ({ id }) => {
  const classes = useStyles();
  const editController = useEditController({
    basePath: '/customers',
    id,
    resource: 'customers',
  });
  const { record } = editController;

  return (
    <Paper className={classes.root}>
      <Grid container>
        <Grid container>
          <Grid item xs={12}>
            <b>Thông tin chi tiết khách hàng</b> &nbsp; (
            <Link to={linkToRecord('/customers', record?.id, 'edit')}>
              Chỉnh sửa
            </Link>
            )
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={2}>
            <Labeled label="Giới tính">
              <TextField record={record} source="sex" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled label="Ngày tạo">
              <DateField record={record} source="created_at" locales="vi-VN" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled label="Phone">
              <TextField record={record} source="phone" locales="vi-VN" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Labeled label="Link Facebook">
              <LinkField record={record} source="facebook" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Labeled label="Link Instagram">
              <LinkField record={record} source="instagram" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Labeled label="Ghi chú">
              <TextField
                record={record}
                source="notes"
                defaultValue="<Không có>"
              />
            </Labeled>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

const CustomerInfo = (props) => {
  const { filterValues } = useListContext(props);

  return filterValues?.customer_id ? (
    <CustomerCard id={filterValues?.customer_id} />
  ) : (
    <></>
  );
};

export default CustomerInfo;
