import React from 'react';
import './App.css';
import { Admin, Button, fetchUtils, Resource } from 'react-admin';
import { Timeline, People } from '@material-ui/icons';
import Paperbase from './Firebase/Paperbase';
import simpleRestProvider from './resources/provider';
import UserList from './resources/users';
import CustomerList from './customers/customers';
import ImageList from './resources/images';
import addUploadCapabilities from './resources/provider_with_upload';
import HistoryList from './histories/HistoryList';
import AuthProvider from './AuthProvider';
import MyLoginPage from './auth/MyLoginPage';

const AppTheme = (props) => {
  const { children } = props;

  return children;
};

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = window.localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = simpleRestProvider(
  process.env.REACT_APP_API_URL,
  httpClient,
);
const dataProviderWithUploader = addUploadCapabilities(dataProvider);

const App = () => (
  <AppTheme
    title="Paperbase theme - Material-UI"
    description={`A page that mimics Firebase.
        This item includes theming using the theme provider component.`}
  >
    <Admin
      loginPage={MyLoginPage}
      layout={Paperbase}
      dataProvider={dataProviderWithUploader}
      authProvider={AuthProvider}
    >
      <Resource name="images" options={{ disabled: true }} />
      <Resource
        name="customers"
        options={{ label: 'Khách hàng', icon: <People /> }}
        list={CustomerList}
      />
      <Resource
        name="histories"
        options={{ label: 'Lịch sử', icon: <Timeline /> }}
        list={HistoryList}
      />
    </Admin>
  </AppTheme>
);

export default App;
