import React from 'react';
import { AppBar, Dialog, IconButton, Toolbar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  modal: {
    padding: theme.spacing(2, 2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 1),
    },
  },
  appBar: {
    position: 'sticky',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
});

const CustomDialog = ({ children, classes, open, handleClose, title }) => (
  <Dialog
    scroll="paper"
    fullScreen
    open={open}
    anchor="right"
    onClose={handleClose}
    className={classes.modal}
  >
    <AppBar className={classes.appBar}>
      <Toolbar>
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
        <IconButton color="inherit" onClick={handleClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
    {children}
  </Dialog>
);

export default withStyles(styles)(CustomDialog);
