import React from 'react';
import { Route } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import {
  Create,
  Datagrid,
  DateField,
  DateInput,
  EditButton,
  RadioButtonGroupInput,
  required,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import List from '../Firebase/List';
import Edit from '../Firebase/Edit';
import CustomDialog from '../Firebase/CustomDialog';
import HistoryButton from '../Firebase/HistoryButton';
import HistoryList from '../resources/histories';
import LinkField from '../components/LinkField';

const styles = (theme) => ({
  table: {
    [theme.breakpoints.down('xs')]: {
      whiteSpace: 'nowrap',
    },
  },
  editBtn: {
    padding: theme.spacing(0, 1),
    fontSize: '10px',
  },
  tableBody: {
    padding: theme.spacing(0, 1),
  },
  rowCell: {
    padding: theme.spacing(0, 1),
  },
});

const CustomerList = (props) => {
  const { classes } = props;
  const history = useHistory();
  const handleClose = () => {
    history.goBack();
  };

  const onCreatedSuccess = (resp) => {
    if (window.redirect_to_history_after_customer_created) {
      window.redirect_to_history_after_customer_created = false;
      window.created_customer_id = resp.data.id;
      history.push(`/histories/create`);
    } else {
      history.goBack();
    }
  };

  const goBack = (resp) => {
    history.goBack();
  };

  return (
    <>
      <List {...props} textFilters={['name', 'phone']}>
        <Datagrid
          className={classes.table}
          classes={{ tbody: classes.tableBody, rowCell: classes.rowCell }}
        >
          <TextField source="name" label="Tên khách hàng" />
          <TextField source="phone" label="Số điện thoại" />
          <LinkField source="facebook" label="Link FB" />
          <LinkField source="instagram" label="Link Instagram" />
          <DateField source="created_at" label="Ngày tạo" locales="vi-VN" />
          <EditButton basePath="/customers" />
          <HistoryButton />
        </Datagrid>
      </List>
      <Route path="/customers/create">
        {({ match }) => (
          <CustomDialog
            open={match !== null && match !== undefined}
            anchor="right"
            handleClose={handleClose}
            title="Tạo mới khách hàng"
          >
            <Create
              title="Create a Collection"
              {...props}
              onSuccess={onCreatedSuccess}
              undoable={false}
            >
              <SimpleForm>
                <TextInput source="name" validate={[required()]} fullWidth />
                <RadioButtonGroupInput
                  validate={[required()]}
                  source="sex"
                  label="Giới tính"
                  choices={[
                    { id: 'Nam', name: 'Nam' },
                    { id: 'Nữ', name: 'Nữ' },
                    { id: 'Khác', name: 'Khác' },
                  ]}
                />
                <TextInput source="phone" validate={[required()]} fullWidth />
                <TextInput source="email" fullWidth label="Email" />
                <TextInput source="location" fullWidth label="Địa Chỉ" />
                <DateInput source="birthday" fullWidth label="Ngày sinh" />
                <TextInput source="facebook" fullWidth />
                <TextInput source="instagram" fullWidth />
                <TextInput source="notes" />
              </SimpleForm>
            </Create>
          </CustomDialog>
        )}
      </Route>
      <Route path="/customers/:id">
        {({ match }) => {
          const isMatch =
            match &&
            match.params &&
            match.params.id !== 'create' &&
            match.isExact;
          return (
            <CustomDialog
              open={isMatch}
              handleClose={handleClose}
              title="Chỉnh sửa khách hàng"
            >
              {isMatch && (
                <Edit
                  {...props}
                  id={match.params.id}
                  onSuccess={goBack}
                  undoable={false}
                >
                  <SimpleForm>
                    <TextField source="id" validate={[required()]} />
                    <TextInput
                      source="name"
                      validate={[required()]}
                      fullWidth
                    />
                    <RadioButtonGroupInput
                      validate={[required()]}
                      source="sex"
                      label="Giới tính"
                      choices={[
                        { id: 'Nam', name: 'Nam' },
                        { id: 'Nữ', name: 'Nữ' },
                        { id: 'Khác', name: 'Khác' },
                      ]}
                    />
                    <TextInput
                      source="phone"
                      validate={[required()]}
                      fullWidth
                    />
                    <TextInput source="email" fullWidth label="Email" />
                    <TextInput source="location" fullWidth label="Địa Chỉ" />
                    <DateInput source="birthday" fullWidth label="Ngày sinh" />
                    <TextInput source="facebook" fullWidth />
                    <TextInput source="instagram" fullWidth />
                    <DateField
                      source="created_at"
                      validate={[required()]}
                      locales="vi-VN"
                      showTime
                    />
                    <TextInput source="notes" fullWidth multiline rows={5} />
                  </SimpleForm>
                </Edit>
              )}
            </CustomDialog>
          );
        }}
      </Route>
    </>
  );
};

export default withStyles(styles)(CustomerList);
