import * as React from 'react';
import MuiGridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import { makeStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import {
  linkToRecord,
  NumberField,
  TextField,
  useListContext,
} from 'react-admin';
import { Link } from 'react-router-dom';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  gridList: {
    margin: 0,
  },
  tileBar: {
    fontSize: '15px',
    background:
      'linear-gradient(to top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.4) 70%,rgba(0,0,0,0) 100%)',
  },
  titleBarName: {
    fontSize: '20px',
  },
  titleBarSubtitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '14px',
  },
  titleBarSubtitleBadge: {
    background: 'orange',
    padding: '5px 10px',
    borderRadius: '50%',
    fontWeight: 'bold',
  },
  placeholder: {
    backgroundColor: theme.palette.grey[300],
    height: '100%',
  },
  price: {
    display: 'inline',
    fontSize: '1em',
  },
  link: {
    color: '#fff',
  },
}));

const getColsForWidth = (width) => {
  if (width === 'xs') return 2;
  if (width === 'sm') return 3;
  if (width === 'md') return 3;
  if (width === 'lg') return 5;
  return 6;
};

const times = (nbChildren, fn) =>
  Array.from({ length: nbChildren }, (_, key) => fn(key));

const LoadingGridList = (props) => {
  const { width, nbItems = 20 } = props;
  const classes = useStyles();
  return (
    <MuiGridList
      cellHeight={180}
      cols={getColsForWidth(width)}
      className={classes.gridList}
    >
      {' '}
      {times(nbItems, (key) => (
        <GridListTile key={key}>
          <div className={classes.placeholder} />
        </GridListTile>
      ))}
    </MuiGridList>
  );
};

const getThumbnailImages = (record) => {
  if (record.images) {
    return record.images.map((h) => h.thumbnail_url);
  }
  return [];
};

const LoadedGridList = (props) => {
  const { width } = props;
  const { ids, data, basePath } = useListContext();
  const classes = useStyles();

  if (!ids || !data) return null;

  return (
    <MuiGridList
      cellHeight={180}
      cols={getColsForWidth(width)}
      className={classes.gridList}
    >
      {ids.map((id) => (
        <GridListTile
          // @ts-ignore
          component={Link}
          key={id}
          to={linkToRecord(basePath, data[id].id)}
        >
          <img src={_.first(getThumbnailImages(data[id]))} alt="" />
          <GridListTileBar
            className={classes.tileBar}
            title={
              <span>
                <TextField
                  className={classes.titleBarName}
                  source="customer.name"
                  record={data[id]}
                />
              </span>
            }
            subtitle={
              <div className={classes.titleBarSubtitle}>
                <NumberField
                  className={classes.price}
                  source="price"
                  record={data[id]}
                  color="inherit"
                  locales="vi-VN"
                  options={{
                    style: 'currency',
                    currency: 'VND',
                  }}
                />
                <div>
                  <span className={classes.titleBarSubtitleBadge}>
                    {data[id].images?.length ?? 0}
                  </span>{' '}
                  hình ảnh
                </div>
              </div>
            }
          />
        </GridListTile>
      ))}
    </MuiGridList>
  );
};

const GridList = (props) => {
  const { width } = props;
  const { loaded } = useListContext();
  return loaded ? (
    <LoadedGridList width={width} />
  ) : (
    <LoadingGridList width={width} />
  );
};

export default withWidth()(GridList);
