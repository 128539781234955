import React, { useEffect, useState } from 'react';
import {
  AutocompleteInput,
  Create,
  Datagrid,
  DateField,
  DateInput,
  EditButton,
  ImageField,
  NumberField,
  ReferenceInput,
  required,
  SimpleForm,
  TextField,
  TextInput,
  Title,
  useListContext,
} from 'react-admin';
import { Route } from 'react-router';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { makeStyles } from '@material-ui/core/styles';
import GridList from './GridList';
import List from '../Firebase/List';
import CustomDialog from '../Firebase/CustomDialog';
import Edit from '../Firebase/Edit';
import ImageInput from '../components/ImageInput';
import 'react-image-gallery/styles/css/image-gallery.css';
import HistoryImageGallery from './HistoryImageGallery';
import ImageFieldWithMeta from './ImageField';

const useStyles = makeStyles((theme) => ({
  dateSearch: {
    [theme.breakpoints.down('xs')]: {
      width: 'calc((100vw - 48px - 16px) / 2) !important',
    },
  },
  nameSearch: {
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100vw - 48px) !important',
    },
  },
  table: {
    [theme.breakpoints.down('xs')]: {
      whiteSpace: 'nowrap',
    },
  },
  tableBody: {
    padding: theme.spacing(0, 1),
  },
  rowCell: {
    padding: theme.spacing(0, 1),
  },
  removeButton: {
    display: 'inline-block',
    position: 'relative',
    float: 'left',
    '& button': {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
      minWidth: theme.spacing(2),
      opacity: 1,
    },
  },
}));

const convertCurrencyToValue = (str) => {
  // eslint-disable-next-line prefer-regex-literals
  const value = str.replace(new RegExp(`\\.`, 'g'), '');
  console.log(value ? Number.parseInt(value, 10) : null);
  return value ? Number.parseInt(value, 10) : null;
};

const formatValueToCurrency = (value) =>
  value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '';

const HistoryList = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [showGallery, setShowGallery] = useState(false);
  const [createdCustomerId, setCreatedCustomerId] = useState();
  const handleClose = () => {
    history.goBack();
  };

  useEffect(() => {
    setCreatedCustomerId(window.created_customer_id);
  }, [window.created_customer_id]);

  const createCustomer = () => {
    window.redirect_to_history_after_customer_created = true;
    history.push(`/customers/create`);
  };

  const openGallery = () => {
    setShowGallery(true);
  };

  const closeGallery = () => {
    setShowGallery(false);
  };

  return (
    <>
      <List
        perPage={20}
        sort={{ field: 'reference', order: 'ASC' }}
        {...props}
        filters={[
          <ReferenceInput
            reference="customers"
            source="customer_id"
            alwaysOn
            placeholder="def"
            resettable
            label="Tên khách hàng"
            fullWidth
            className={classes.nameSearch}
            filterToQuery={(searchText) =>
              searchText ? { name: searchText } : {}
            }
          >
            <AutocompleteInput
              optionText="name"
              placeholder="abc"
              options={{
                suggestionsContainerProps: {
                  modifiers: {
                    computeStyle: {
                      enabled: true,
                      gpuAcceleration: false,
                    },
                    applyStyle: {
                      enabled: true,
                    },
                  },
                },
              }}
            />
          </ReferenceInput>,
          <DateInput
            label="Từ ngày"
            source="from"
            alwaysOn
            fullWidth
            className={classes.dateSearch}
          />,
          <DateInput
            label="Đến ngày"
            source="to"
            alwaysOn
            fullWidth
            className={classes.dateSearch}
          />,
        ]}
        textFilters={[]}
      >
        <Datagrid
          rowClick="edit"
          className={classes.table}
          classes={{ tbody: classes.tableBody, rowCell: classes.rowCell }}
        >
          <TextField source="name" label="Tên hình xăm" />
          <TextField source="customer.name" label="Tên khách hàng" />
          <NumberField
            label="Giá tiền"
            source="price"
            color="inherit"
            locales="vi-VN"
            options={{
              style: 'currency',
              currency: 'VND',
            }}
          />
          <DateField
            source="created_at"
            label="Ngày thực hiện"
            locales="vi-VN"
          />
          <EditButton basePath="/histories" />
        </Datagrid>
      </List>
      <Route exact path="/histories/create">
        {({ match }) => (
          <CustomDialog
            open={match !== null && match !== undefined}
            anchor="right"
            handleClose={handleClose}
            title="Tạo mới lịch sử"
          >
            <Create title="Create a Collection" {...props} undoable={false}>
              <SimpleForm>
                <Button
                  onClick={createCustomer}
                  color="primary"
                  variant="outlined"
                >
                  <AddIcon /> Tạo mới khách
                </Button>
                <ReferenceInput
                  reference="customers"
                  source="customer_id"
                  label="Tên khách hàng"
                  validate={[required()]}
                  fullWidth
                  filterToQuery={(searchText) =>
                    searchText ? { name: searchText } : {}
                  }
                  defaultValue={createdCustomerId}
                >
                  <AutocompleteInput optionText="name" resettable />
                </ReferenceInput>
                <TextInput source="name" label="Tên hình xăm" fullWidth />
                <TextInput
                  source="price"
                  validate={[required()]}
                  label="Tổng tiền"
                  parse={convertCurrencyToValue}
                  format={formatValueToCurrency}
                  fullWidth
                />
                <DateInput
                  label="Thời điểm thực hiện"
                  source="created_at"
                  validate={[required()]}
                  locales="vi-VN"
                />
                <ImageInput
                  multiple
                  source="images"
                  label="Hình ảnh đính kèm"
                  labelMultiple="Click hoặc thả file hình ảnh vào đây"
                  accept="image/*"
                  classes={classes}
                >
                  <ImageFieldWithMeta
                    source="thumbnail_url"
                    title="title"
                    onClick={openGallery}
                  />
                </ImageInput>
              </SimpleForm>
            </Create>
          </CustomDialog>
        )}
      </Route>
      <Route path="/histories/:id">
        {({ match }) => {
          const isMatch = match && match.params && match.params.id !== 'create';
          return (
            <CustomDialog
              open={isMatch}
              handleClose={handleClose}
              title="Chỉnh sửa lịch sử"
            >
              {isMatch && (
                <Edit undoable={false} {...props} id={match.params.id}>
                  <SimpleForm>
                    <TextField source="id" validate={[required()]} />
                    <TextField source="customer.name" label="Tên khách hàng" />
                    <TextInput source="name" label="Tên hình xăm" fullWidth />
                    <TextInput
                      source="price"
                      validate={[required()]}
                      fullWidth
                      parse={convertCurrencyToValue}
                      format={formatValueToCurrency}
                    />
                    <DateField
                      label="Thời điểm thực hiện"
                      locales="vi-VN"
                      source="created_at"
                      validate={[required()]}
                    />
                    <ImageInput
                      multiple
                      source="images"
                      label="Hình ảnh đính kèm"
                      labelMultiple="Click hoặc thả file hình ảnh vào đây"
                      accept="image/*"
                      classes={classes}
                    >
                      <ImageFieldWithMeta
                        source="thumbnail_url"
                        title="title"
                        onClick={openGallery}
                      />
                    </ImageInput>
                    <HistoryImageGallery
                      showGallery={showGallery}
                      closeGallery={closeGallery}
                    />
                  </SimpleForm>
                </Edit>
              )}
            </CustomDialog>
          );
        }}
      </Route>
    </>
  );
};

const ProductListView = () => {
  const { defaultTitle } = useListContext();
  return (
    <>
      <Title defaultTitle={defaultTitle} />
      <GridList />
    </>
  );
};

export default HistoryList;
