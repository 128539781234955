import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListIcon from '@material-ui/icons/List';
import { shallowEqual, useSelector } from 'react-redux';
import { getResources } from 'ra-core';
import { MenuItemLink } from 'react-admin';

const styles = (theme) => ({
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  item: {
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover,&:focus': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  itemCategory: {
    backgroundColor: '#232f3e',
    boxShadow: '0 -1px 0 #404854 inset',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  firebase: {
    fontSize: 24,
    color: theme.palette.common.white,
  },
  itemActiveItem: {
    color: '#4fc3f7',
  },
  itemPrimary: {
    fontSize: 'inherit',
  },
  itemIcon: {
    minWidth: 'auto',
    marginRight: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
});

const Navigator = (props) => {
  const resources = useSelector(getResources, shallowEqual);
  const { classes, ...other } = props;

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem
          className={clsx(classes.firebase, classes.item, classes.itemCategory)}
        >
          Paperbase
        </ListItem>
        {resources.map(({ name, options }) => (
          <>
            {options?.disabled === undefined && (
              <React.Fragment key={name}>
                <ListItem className={classes.categoryHeader}>
                  <ListItemText
                    classes={{
                      primary: classes.categoryHeaderPrimary,
                    }}
                  >
                    {options.label ?? name}
                  </ListItemText>
                </ListItem>
                <MenuItemLink
                  key={name}
                  to={`/${name}`}
                  classes={{
                    active: classes.itemActiveItem,
                  }}
                  className={clsx(classes.item)}
                  primaryText="Danh sách"
                  leftIcon={options?.icon ?? <ListIcon />}
                  dense
                  sidebarIsOpen
                />

                <Divider className={classes.divider} />
              </React.Fragment>
            )}
          </>
        ))}
      </List>
    </Drawer>
  );
};

export default withStyles(styles)(Navigator);
