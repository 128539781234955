import React, { useEffect, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import CustomDialog from '../Firebase/CustomDialog';

const HistoryImageGallery = ({ showGallery, closeGallery, record }) => {
  const [images, setImages] = useState([]);
  useEffect(() => {
    if (record.images) {
      setImages(
        record.images?.map((i) => ({
          original: i.thumbnail_url,
          thumbnail: i.thumbnail_url,
        })),
      );
    }
  }, [record.images]);

  useEffect(() => {
    if (showGallery && !record.images) {
      closeGallery();
    }
  }, [showGallery]);

  return (
    <CustomDialog
      open={showGallery}
      title="Xem chi tiết hình ảnh"
      handleClose={closeGallery}
    >
      <ImageGallery items={images} />
    </CustomDialog>
  );
};

export default HistoryImageGallery;
