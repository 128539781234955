import * as React from 'react';
import {
  Children,
  cloneElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  getListControllerProps,
  ListContext,
  useCheckMinimumRequiredProps,
  useListContext,
  useListController,
} from 'ra-core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import {
  BulkActionsToolbar,
  BulkDeleteButton,
  FilterForm,
  Pagination,
  RefreshButton,
} from 'react-admin';
import { FilterContext } from 'ra-ui-materialui';
import { Link, useHistory } from 'react-router-dom';
import { debounce } from 'lodash/function';
import { RemoveCircle } from '@material-ui/icons';
import _ from 'lodash';
import CustomerInfo from '../histories/CustomerInfo';

const styles = (theme) => ({
  paper: {
    maxWidth: 1200,
    margin: 'auto',
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    margin: '40px 16px',
    wordBreak: 'break-word',
    [theme.breakpoints.down('xs')]: {
      overflow: 'auto',
    },
  },
  sideToolbar: {
    flex: 1,
    alignSelf: 'center',
    textAlign: 'end',
  },
  toolBarFilter: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
    },
  },
});

const SideToolbar = ({ basePath, classes, ...rest }) => {
  const filter = useListContext({ basePath, classes, ...rest });

  useEffect(() => {
    if (filter.filterValues.customer_id) {
      window.created_customer_id = filter.filterValues.customer_id;
    }
  }, [filter.filterValues?.customer_id]);

  return (
    <Grid item className={classes.sideToolbar}>
      <Button
        variant="contained"
        component={Link}
        to={`${basePath}/create`}
        color="primary"
        className={classes.addUser}
      >
        <AddIcon />
      </Button>
      <RefreshButton />
    </Grid>
  );
};

const DefaultBulkActionButtons = (props) => <BulkDeleteButton {...props} />;

const ListView = (props) => {
  const pagination = <Pagination />;
  const bulkActionButtons = <DefaultBulkActionButtons />;
  const { children, classes, filters, actions, textFilters } = props;
  const controllerProps = getListControllerProps(props); // deprecated, to be removed in v4
  const listContext = useListContext(props);
  const { basePath, filterValues, setFilters } = listContext;
  const history = useHistory();
  const [filter, setFilter] = useState();
  const textFiltersArray =
    textFilters instanceof Array ? textFilters : [textFilters];

  useEffect(() => {
    const keys = Object.keys(filterValues);
    // If query params includes values we used in textFilters, set value
    if (
      _.differenceWith(textFiltersArray, keys, _.isEqual).length <
      textFiltersArray.length
    ) {
      setFilter(_.first(Object.values(filterValues)));
    }
  }, []);

  const filterText = (value) => {
    const textFiltersWithValues = textFiltersArray.reduce((result, f) => {
      result[f] = value;
      return result;
    }, {});
    history.push(`${basePath}?filter=${JSON.stringify(textFiltersWithValues)}`);
  };

  const removeFilter = () => {
    setFilter('');
    filterText('');
  };

  const debouncedFilter = useCallback(debounce(filterText, 500), []);

  return (
    <Paper className={classes.paper}>
      <AppBar
        className={classes.searchBar}
        position="static"
        color="default"
        elevation={0}
      >
        {filters && (
          <FilterContext.Provider value={filters}>
            <Toolbar className={classes.toolBarFilter}>
              <Grid container alignItems="center">
                <Grid item>
                  <FilterForm />
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setFilters({})}
                  >
                    Xóa tìm kiếm
                  </Button>
                </Grid>
                <SideToolbar classes={classes} basePath={basePath} />
              </Grid>
            </Toolbar>
            <Toolbar>
              <CustomerInfo />
            </Toolbar>
          </FilterContext.Provider>
        )}
        {textFiltersArray.length > 0 && (
          <Toolbar>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                {!filter && (
                  <SearchIcon className={classes.block} color="inherit" />
                )}
                {filter && (
                  <RemoveCircle
                    cursor="pointer"
                    className={classes.block}
                    color="inherit"
                    onClick={removeFilter}
                  />
                )}
              </Grid>
              <Grid item xs>
                <TextField
                  value={filter}
                  onChange={(e) => {
                    setFilter(e.target.value);
                    debouncedFilter(e.target.value);
                  }}
                  fullWidth
                  placeholder="Search by email address, phone number, or user UID"
                  InputProps={{
                    disableUnderline: true,
                    className: classes.searchInput,
                  }}
                />
              </Grid>
              <SideToolbar classes={classes} basePath={basePath} />
            </Grid>
          </Toolbar>
        )}
      </AppBar>
      <div className={classes.contentWrapper}>
        {bulkActionButtons !== false && bulkActionButtons && (
          <BulkActionsToolbar {...controllerProps}>
            {bulkActionButtons}
          </BulkActionsToolbar>
        )}
        {children &&
          // @ts-ignore-line
          cloneElement(Children.only(children), {
            ...controllerProps, // deprecated, use ListContext instead, to be removed in v4
            hasBulkActions: bulkActionButtons !== false,
          })}
        {pagination && cloneElement(pagination, listContext)}
      </div>
    </Paper>
  );
};

const List = (props) => {
  useCheckMinimumRequiredProps('List', ['children'], props);
  const controllerProps = useListController(props);
  return (
    <ListContext.Provider value={controllerProps}>
      <ListView {...props} {...controllerProps} />
    </ListContext.Provider>
  );
};

export default withStyles(styles)(List);
