import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Popover from '@material-ui/core/Popover';
import Badge from '@material-ui/core/Badge';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useDataProvider } from 'react-admin';
import NotificationsIcon from '@material-ui/icons/Notifications';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: '50vh',
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    width: '100%',
    backgroundColor: 'inherit',
    padding: 0,
  },
  customer_item: {
    alignItems: 'flex-start',
    borderBottom: '1px solid #cccc',
    paddingRight: 0,
    justifyContent: 'space-between',
  },
  notificationBadge: {
    cursor: 'pointer',
  },
}));

const NotificationList = ({ customersRef }) => {
  const classes = useStyles();
  const [customers, setCustomers] = useState({});
  const [total, setTotal] = useState(0);
  const dataProvider = useDataProvider();
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    setCustomers(customersRef.current?.data || {});
    setTotal(customersRef.current?.total || 0);
  }, [customersRef.current?.data]);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const removeNotification = (customerId, groupId) =>
    dataProvider
      .checkRemind('customers', { id: customerId })
      .then(({ data }) => {
        setCustomers({
          ...customers,
          [groupId]: customers[groupId].filter((c) => c.id !== data.id),
        });
        setTotal(total - 1);
      });

  const removeAllGroup = (groupId) => {
    if (!window.confirm('Đồng ý xóa toàn bộ thông báo trong tháng?')) return;
    const customerGroup = customers[groupId];
    const groupTotal = customerGroup.length;
    for (let i = 0; i < groupTotal; i++) {
      dataProvider.checkRemind('customers', { id: customerGroup[i].id });
    }
    setCustomers({
      ...customers,
      [groupId]: [],
    });
    setTotal(total - groupTotal);
  };

  return (
    <Badge
      badgeContent={total}
      color="secondary"
      className={classes.notificationBadge}
    >
      <NotificationsIcon onClick={openMenu} />
      <Popover
        anchorEl={anchorEl}
        onClose={handleClose}
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <List className={classes.root} subheader={<li />}>
          {Object.keys(customers).map(
            (sectionId) =>
              customers[sectionId].length > 0 && (
                <li
                  key={`section-${sectionId}`}
                  className={classes.listSection}
                >
                  <TransitionGroup component="ul" className={classes.ul}>
                    <ListSubheader>
                      Khách hàng trong {Number.parseInt(sectionId, 10) + 1}{' '}
                      tháng vừa qua
                      <Tooltip title="Xóa toàn bộ thông báo trong tháng">
                        <IconButton
                          aria-label="delete"
                          onClick={() => removeAllGroup(sectionId)}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </ListSubheader>
                    {customers[sectionId].map((item) => (
                      <CSSTransition
                        key={`item-${sectionId}-${item.id}`}
                        timeout={400}
                        classNames="item"
                      >
                        <ListItem className={classes.customer_item}>
                          <div>
                            Khách hàng{' '}
                            <Link
                              to={{
                                pathname: '/histories',
                                search: `filter=${JSON.stringify({
                                  customer_id: item.id,
                                })}`,
                              }}
                            >
                              {item.name}
                            </Link>{' '}
                            đã sử dụng dịch vụ vào ngày{' '}
                            {moment(item.created_at).format('D-M-Y')}{' '}
                          </div>
                          <Tooltip title="Xóa thông báo này">
                            <IconButton
                              aria-label="delete"
                              onClick={() =>
                                removeNotification(item.id, sectionId)
                              }
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </ListItem>
                      </CSSTransition>
                    ))}
                  </TransitionGroup>
                </li>
              ),
          )}
          {total === 0 && (
            <li>
              <ListItem>Không có thông báo mới</ListItem>
            </li>
          )}
        </List>
      </Popover>
    </Badge>
  );
};

export default NotificationList;
