import React, { Children, cloneElement, isValidElement, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import classnames from 'classnames';
import { useInput, useTranslate } from 'ra-core';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import {
  InputHelperText,
  Labeled,
  sanitizeInputRestProps,
} from 'ra-ui-materialui';
import FileInputPreview from 'ra-ui-materialui/esm/input/FileInputPreview';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(
  (theme) => ({
    dropZone: {
      background: theme.palette.background.default,
      cursor: 'pointer',
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.getContrastText(theme.palette.background.default),
    },
    preview: {},
    removeButton: {},
    root: { width: '100%' },
  }),
  { name: 'RaFileInput' },
);

const FileInput = (props) => {
  const [ordering, setOrdering] = useState(false);
  const {
    accept,
    children,
    className,
    classes: classesOverride,
    format,
    helperText,
    label,
    labelMultiple = 'ra.input.file.upload_several',
    labelSingle = 'ra.input.file.upload_single',
    maxSize,
    minSize,
    multiple = false,
    options: { inputProps: inputPropsOptions, ...options } = {},
    parse,
    placeholder,
    resource,
    source,
    validate,
    ...rest
  } = props;
  const translate = useTranslate();
  const classes = useStyles(props);

  // turn a browser dropped file structure into expected structure
  const transformFile = (file) => {
    if (!(file instanceof File)) {
      return file;
    }

    const { source, title } = children.props;

    const preview = URL.createObjectURL(file);
    const transformedFile = {
      rawFile: file,
      [source]: preview,
      fakeId: `image-${Math.floor(Math.random() * 1000000)}`,
    };

    if (title) {
      transformedFile[title] = '';
    }

    return transformedFile;
  };

  const transformFiles = (files) => {
    if (!files) {
      return multiple ? [] : null;
    }

    if (Array.isArray(files)) {
      return files.map(transformFile);
    }

    return transformFile(files);
  };

  const {
    id,
    input: { onChange, value, ...inputProps },
    meta,
    isRequired,
  } = useInput({
    format: format || transformFiles,
    parse: parse || transformFiles,
    source,
    type: 'file',
    validate,
    ...rest,
  });
  const { touched, error, submitError } = meta;
  const files = value ? (Array.isArray(value) ? value : [value]) : [];
  console.log(files);

  const onDrop = (newFiles, rejectedFiles, event) => {
    const updatedFiles = multiple ? [...files, ...newFiles] : [...newFiles];

    if (multiple) {
      onChange(updatedFiles);
    } else {
      onChange(updatedFiles[0]);
    }

    if (options.onDrop) {
      options.onDrop(newFiles, rejectedFiles, event);
    }
  };

  const onRemove = (file) => () => {
    if (multiple) {
      const filteredFiles = files.filter(
        (stateFile) => !shallowEqual(stateFile, file),
      );
      onChange(filteredFiles);
    } else {
      onChange(null);
    }

    if (options.onRemove) {
      options.onRemove(file);
    }
  };

  const childrenElement =
    children && isValidElement(Children.only(children))
      ? Children.only(children)
      : undefined;

  const { getRootProps, getInputProps } = useDropzone({
    ...options,
    accept,
    maxSize,
    minSize,
    multiple,
    onDrop,
  });

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(files, result.source.index, result.destination.index);

    onChange(items);
  };

  const grid = 8;

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'lightblue' : 'transparent',
    display: ordering ? 'flex' : 'block',
    padding: grid,
    overflow: 'auto',
  });

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: ordering ? grid * 2 : 0,
    margin: ordering ? `0 ${grid}px 0 0` : 0,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'transparent',

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  return (
    <Labeled
      id={id}
      label={label}
      className={classnames(classes.root, className)}
      source={source}
      resource={resource}
      isRequired={isRequired}
      meta={meta}
      {...sanitizeInputRestProps(rest)}
    >
      <>
        <div
          data-testid="dropzone"
          className={classes.dropZone}
          {...getRootProps()}
        >
          <input
            id={id}
            {...getInputProps({
              ...inputProps,
              ...inputPropsOptions,
            })}
          />
          {placeholder ||
            (multiple ? (
              <p>{translate(labelMultiple)}</p>
            ) : (
              <p>{translate(labelSingle)}</p>
            ))}
        </div>
        <FormHelperText>
          <InputHelperText
            touched={touched}
            error={error || submitError}
            helperText={helperText}
          />
        </FormHelperText>
        <Button
          variant="contained"
          color="primary"
          style={{ marginBottom: '15px' }}
          onClick={() => setOrdering(!ordering)}
        >
          {ordering ? 'Hoàn tất sắp xếp' : 'Sắp xếp lại ảnh'}
        </Button>
        {children && (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" direction="horizontal">
              {(provided, snapshot) => (
                <div
                  className="previews"
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                  {...provided.droppableProps}
                >
                  {files.map((file, index) => (
                    <Draggable
                      isDragDisabled={!ordering}
                      key={file.id ?? file.fakeId}
                      draggableId={`file+${file.id ?? file.fakeId}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                          )}
                        >
                          <FileInputPreview
                            file={file}
                            onRemove={onRemove(file)}
                            className={classes.removeButton}
                          >
                            {cloneElement(childrenElement, {
                              record: file,
                              className: classes.preview,
                            })}
                          </FileInputPreview>
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </>
    </Labeled>
  );
};

export default FileInput;
