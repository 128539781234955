import React from 'react';
import { DateInput, ImageField } from 'ra-ui-materialui';
import { makeStyles } from '@material-ui/core/styles';
import { DateField, SimpleForm, TextField, TextInput } from 'react-admin';
import { Link, useRouteMatch, useHistory } from 'react-router-dom';
import { Route } from 'react-router';
import CustomDialog from '../Firebase/CustomDialog';
import Edit from '../Firebase/Edit';

const useStyles = makeStyles((theme) => ({
  metaBox: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  image: {
    margin: '0.5rem',
    maxHeight: '15rem',
  },
  info: {
    fontSize: '15px',
  },
  imageFull: {
    maxHeight: 'none',
    maxWidth: '100%',
  },
}));

const ImageFieldWithMeta = (props) => {
  const classes = useStyles();
  const { record } = props;
  let path = '';
  let url = '';
  const match = useRouteMatch();
  if (match) {
    path = match.path;
    url = match.url;
  }
  const history = useHistory();
  const handleClose = (reload) => {
    if (reload !== false) {
      setTimeout(() => {
        window.location.reload();
      }, 100);
    }
    history.goBack();
  };

  return (
    <div>
      <ImageField classes={classes} {...props} />
      <div className={classes.metaBox}>
        {record?.id && (
          <div className={classes.info}>
            (<Link to={`${url}/images/${record?.id}`}>Chỉnh sửa</Link>)
          </div>
        )}
        {!record?.id && (
          <div>
            <b>Lưu lại lịch sử trước khi chỉnh sửa</b>
          </div>
        )}
        <div className={classes.info}>
          Tiêu đề:{' '}
          <TextField className={classes.info} source="title" record={record} />
        </div>
        <div className={classes.info}>
          Vào ngày:{' '}
          <DateField
            className={classes.info}
            locales="vi-VN"
            source="date"
            record={record}
          />
        </div>
      </div>
      <Route path={`${path}/images/:image`}>
        {({ match }) => {
          const isMatch =
            match &&
            match.params &&
            Number.parseInt(match.params.image, 10) === record?.id;
          return (
            isMatch && (
              <CustomDialog
                open
                title="Chỉnh sửa thông tin ảnh"
                handleClose={() => handleClose(false)}
              >
                <Edit
                  basePath="/images"
                  resource="images"
                  id={record?.id}
                  onSuccess={handleClose}
                  undoable={false}
                >
                  <SimpleForm>
                    <TextInput fullWidth source="title" label="Tên ảnh" />
                    <DateInput source="date" label="Ngày ghi nhận ảnh" />
                    <ImageField
                      fullWidth
                      classes={{ image: classes.imageFull }}
                      source="thumbnail_url"
                      label="Hình ảnh"
                    />
                  </SimpleForm>
                </Edit>
              </CustomDialog>
            )
          );
        }}
      </Route>
    </div>
  );
};

export default ImageFieldWithMeta;
