import { fetchUtils } from 'ra-core';
import { stringify } from 'query-string';

export default (apiUrl, httpClient = fetchUtils.fetchJson) => ({
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    let includes = '';
    if (resource === 'histories') {
      includes = 'images,customer';
    }
    let query = {
      sort: 'created_at',
      order: 'desc',
      page: JSON.stringify(page - 1),
      limit: JSON.stringify(perPage),
      includes,
    };
    query = { ...query, ...params.filter };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ json }) => ({
      data: json.data,
      total: json.meta_data.total,
    }));
  },

  getOne: (resource, params) => {
    let includes = '';
    if (resource === 'histories') {
      includes = 'images,customer';
    }
    return httpClient(
      `${apiUrl}/${resource}/${params.id}?includes=${includes}`,
    ).then(({ json }) => ({
      data: json,
    }));
  },
  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => json);
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => {
      if (!headers.has('content-range')) {
        throw new Error(
          'The Content-Range header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?',
        );
      }
      return {
        data: json,
        total: parseInt(headers.get('content-range').split('/').pop(), 10),
      };
    });
  },

  update: (resource, params) => {
    let includes = [];
    if (resource === 'histories') {
      includes = ['customer,images'];
    }
    return httpClient(
      `${apiUrl}/${resource}/${params.id}?includes=${includes}`,
      {
        method: 'PUT',
        body: JSON.stringify(params.data),
      },
    ).then(({ json }) => ({ data: json }));
  },

  // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
  updateMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'PUT',
          body: JSON.stringify(params.data),
        }),
      ),
    ).then((responses) => ({ data: responses.map(({ json }) => json.id) })),

  create: (resource, params) => {
    let includes = [];
    if (resource === 'histories') {
      includes = ['customer,images'];
    }
    return httpClient(`${apiUrl}/${resource}?includes=${includes}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    }));
  },

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json })),

  // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
  deleteMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'DELETE',
        }),
      ),
    ).then((responses) => ({ data: responses.map(({ json }) => json.id) })),

  uploadFile: (resource, params) => {
    const formData = new FormData();
    formData.append('file', params);
    formData.append('name', params.name);
    return httpClient(`${apiUrl}/images/upload`, {
      method: 'POST',
      body: formData,
    }).then(({ json }) => ({ data: json }));
  },

  checkRemind: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/check-remind/${params.id}`, {
      method: 'PUT',
    }).then(({ json }) => ({ data: json })),
});
