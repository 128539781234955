import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Logout, useDataProvider } from 'react-admin';
import moment from 'moment';
import NotificationList from '../notifications/NotificationList';

const lightColor = 'rgba(255, 255, 255, 0.7)';

const styles = (theme) => ({
  secondaryBar: {
    zIndex: 0,
  },
  menuButton: {
    marginLeft: -theme.spacing(1),
  },
  iconButtonAvatar: {
    padding: 4,
  },
  link: {
    textDecoration: 'none',
    color: lightColor,
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
  button: {
    borderColor: lightColor,
  },
});

const Header = (props) => {
  const { classes, onDrawerToggle } = props;
  const dataProvider = useDataProvider();
  const customers = useRef();
  const groups = [0, 1, 2, 5, 11];
  const now = moment();

  useEffect(() => {
    groups.forEach((g, index) => {
      dataProvider
        .getList('customers', {
          pagination: { page: 1, perPage: 10000 },
          sort: { field: 'id', order: 'desc' },
          filter: {
            created_at_from: now
              .clone()
              .subtract(g + 2, 'months')
              .format('Y-M-D'),
            created_at_to: now
              .clone()
              .subtract(g + 1, 'months')
              .format('Y-M-D'),
            remind: index,
          },
        })
        .then((resp) => {
          if (resp) {
            const { data } = resp;
            customers.current = {
              ...customers.current,
              total: data.length + (customers.current?.total ?? 0),
              data: {
                ...customers.current?.data,
                [g]: data,
              },
            };
          }
        });
    });
  }, []);

  return (
    <>
      <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Hidden smUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={onDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item xs />
            <Grid item>
              <NotificationList customersRef={customers} />
            </Grid>
            <Grid item>
              <Logout />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar
        component="div"
        className={classes.secondaryBar}
        color="primary"
        position="static"
        elevation={0}
      >
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Oh Deer Tattoo
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
};

Header.propTypes = {
  onDrawerToggle: PropTypes.func.isRequired,
};

export default withStyles(styles)(Header);
