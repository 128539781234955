import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Edit } from 'react-admin';
import * as classnames from 'classnames';

const useStyles = makeStyles({
  paper: {
    maxWidth: 1200,
    margin: 'auto',
    overflow: 'hidden',
  },
});

const AppPaperWrapper = ({ className, children }) => {
  const classes = useStyles();
  return (
    <Paper className={classnames(classes.paper, className)}>{children}</Paper>
  );
};

const StylesEdit = (props) => <Edit component={AppPaperWrapper} {...props} />;

export default StylesEdit;
