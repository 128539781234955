/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = (file) => {
  if (file.rawFile instanceof File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.rawFile);

      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });
  }
  return new Promise((resolve) => {
    resolve(null);
  });
};

/**
 * For posts update only, convert uploaded image in base 64 and attach it to
 * the `picture` sent property, with `src` and `title` attributes.
 */
const addUploadCapabilities = (dataProvider) => ({
  ...dataProvider,
  create: (resource, params) => {
    if (resource !== 'histories' || !params.data.images) {
      // fallback to the default implementation
      return dataProvider.create(resource, params);
    }

    return Promise.all(params.data.images.map(convertFileToBase64))
      .then((base64Pictures) =>
        params.data.images.map((p, index) => {
          if (p.rawFile instanceof File) {
            return {
              src: base64Pictures[index],
              title: '',
            };
          }
          return p;
        }),
      )
      .then((transformedNewPictures) =>
        dataProvider.create(resource, {
          ...params,
          data: {
            ...params.data,
            images: [...transformedNewPictures],
          },
        }),
      );
  },
  update: (resource, params) => {
    if (resource !== 'histories' || !params.data.images) {
      // fallback to the default implementation
      return dataProvider.update(resource, params);
    }

    return Promise.all(params.data.images.map(convertFileToBase64))
      .then((base64Pictures) =>
        params.data.images.map((p, index) => {
          if (p.rawFile instanceof File) {
            return {
              src: base64Pictures[index],
              title: '',
            };
          }
          return p;
        }),
      )
      .then((transformedNewPictures) =>
        dataProvider.update(resource, {
          ...params,
          data: {
            ...params.data,
            images: [...transformedNewPictures],
          },
        }),
      );
  },
});

export default addUploadCapabilities;
