import React from 'react';
import { ShowChartOutlined } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router';

const HistoryButton = (props) => {
  const { record } = props;
  const history = useHistory();
  return (
    <Button
      color="primary"
      onClick={(e) => {
        e.stopPropagation();
        history.push(
          `/histories/?filter=${JSON.stringify({
            customer_id: record.id,
          })}`,
        );
      }}
    >
      <ShowChartOutlined /> Xem lịch sử
    </Button>
  );
};

export default HistoryButton;
